* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    /* overflow: auto; */
}

.main-title {
    position: fixed;
    padding: 20px;
    background: white;
    z-index: 10;
    width: 100%;
    top:0;
}

.main-layout {
    width: 100%;
    height: 100%;
    padding: 70px 20px 20px ;
}

.main-tab {
    /* width: min-content; */
    width: 100%;
}

:where(.css-dev-only-do-not-override-f7vrd6).ant-tabs .ant-tabs-tab {
    font-size: 18px;
}

:where(.css-dev-only-do-not-override-f7vrd6).ant-tabs-top > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-f7vrd6).ant-tabs-bottom > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-f7vrd6).ant-tabs-top > div > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-f7vrd6).ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #b8b8b8;
}

.card {
    display: flex;
    flex-wrap: wrap;
    gap:16px;
    margin: 20px 0px 0 0;
}

.card .sub-card {
    /* width: 250px; */
    width: calc(20% - 13px);
    height: 100px;
    padding: 10px 10px 30px 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-size: 14px;
    font-weight:700 ;
    border: 1px solid #969696;
}

.sub-card p {
    color: var(--black_75);
    font-size: 22px;
    font-weight: 700;
    line-height: 140%;
    /* text-align: center; */
}

.sub-layout {
    display: flex;
    gap: 20px;
    width: 100%;
}


.box-layout-1 {
    max-width: 28%;
    width: 100%;
}

.sub-layout .ant-table-content table th{
    font-weight: 700;
}
.sub-layout .ant-table-content tr.custom-summary-row{
    font-weight:700;
}
.sub-layout .ant-table-content table{
    border: 1px solid #969696;
}

.box-layout-3 {
    /* padding: 0px 20px; */
}

.table-layout {
    margin: 20px 0;
    width: 100%;
}

/* Header background color */
.ant-table-thead > tr > th {
    background-color: rgba(128, 147, 179, 0.4) !important;
    color: white; /* Optional: for better readability */
}

/* Summary row background color */
.custom-summary-row {
    background-color: rgba(128, 147, 179, 0.2) !important; /* Lighter shade of header color */
    border-top: 2px solid rgb(128, 147, 179);
    border-bottom: 2px solid rgb(128, 147, 179);
}

.custom-table-row {
    height: 20px;
}

.custom-table .ant-table-cell {
    line-height: 5px;
    border-color: #969696 !important;
    border: none;
}
.sub-layout tfoot.ant-table-summary .custom-summary-row td{
    border-bottom:none;
}
.sub-layout td.ant-table-cell.custom-summary-row.left-text-row:last-child {
    border-right: none !important;
}
.sub-layout .ant-table-cell.custom-table-header.left-text-row{
    border-right: none !important;
}
.main-charts {
    width: 100%;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0px;
}

.main-charts .chart-layout {
    width: calc(50% - 10px);
    border: 1px solid #969696;
    padding: 10px;
}
.card .sub-card:last-child{
    margin-right:0;
}
.main-charts h3 {
    font-size: 25px;
    margin-bottom: 15px;
}

.main-charts .chart-layout canvas {
    padding-top: 20px;
}

.main-charts .bar-chart .canvas-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
}


th.ant-table-cell.custom-table-header {
    min-width: 220px;
}

td.ant-table-cell.custom-table-header.left-text-row,
td.ant-table-cell.custom-summary-row.left-text-row {
    text-align: right;
}

.single-filters {
    /* margin: 15px 0px 0; */
}

.single-filters p {
    /* font-size: 20px; */
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
}

.filters-pane .heading {
    font-size: 25px;
    margin-bottom: 15px;
}
.main-charts .chart-layout.common-chart.pie-chart canvas {
    /* height: unset !important; */
    /* width: unset !important; */
    /* margin: auto; */
}
.main-tab div.ant-tabs-tabpane .ant-spin {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 75px;
    left: 0;
    right: 0;
    margin: auto;
}
.box-layout-3 h3.heading {
    font-size:24px;
    margin-bottom:12px;
}
.box-layout-3 .filters-pane{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.single-filters .ant-select-selector .ant-select-selection-item-content{
    font-size:12px;
}
.box-layout-2{
    max-width: 72%;
    width: 100%;
}
.chart-title{
    padding-top: 40px;
    font-size: 18px;
    font-weight: 700;
}
.chart-layout.bar-chart {
    display: flex;
    flex-direction: column;
}
.filters-pane .single-filters {
    max-width:calc(25% - 10px);
    width:100%;
}
.filters-pane .single-filters .ant-select{
    width:100%;
}
@media screen and (max-width:1024px) {
    .sub-layout{
        flex-wrap: wrap;
    }
    .box-layout-2, .box-layout-1{
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
    .table-layout{
        width: calc(50% - 10px);
    }
}

@media screen and (max-width:767px) {
    .card .sub-card{
        width: calc(50% - 8px);
    }
    .filters-pane .single-filters {
        max-width: calc(50% - 6px);
        width: 100%;
    }
    .sub-layout{
        flex-wrap: wrap;
    }
    .box-layout-2, .box-layout-1{
        max-width: 100%;
    }
    .main-charts .chart-layout{
        width: 100%;
    }
    .table-layout {
        width: 100%;
        margin: 0;
    }
}